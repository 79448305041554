import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { ServicesList } from '../../components/ServicesList';
import { RoundedContainer } from '../../styles/elements';
import { StyledStructured } from '../../components/StyledStructured';
import { ServicesWrapper } from './styles';

const ServicesPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryServicesPage: pageData,
  } = data;
  const { seo, title } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <RoundedContainer>
          <ServicesWrapper>
            <StyledStructured
              data={title}
            />

            <ServicesList showPrice />
          </ServicesWrapper>
        </RoundedContainer>
      </MainContainer>
    </PageWrapper>
  );
};

export default ServicesPageTemplate;

export const query = graphql`
  query ServicesPageQuery($locale: String!) {
    datoCmsSymmetryServicesPage(locale: { eq: $locale }) {
      locale
      title {
        value
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;
