import styled from 'styled-components';
import { media } from 'styles/mixins';

export const ServicesWrapper = styled.div`
    h1 {
        font-size: 54px;
        font-weight: 600;
        line-height: 54px;
        margin-bottom: 40px;

        em {
            color: black;
            font-style: normal;
        }

        ${media.tablet`
          font-size: 40px;
          font-weight: 600;
          line-height: 54px;
        `}

        ${media.mobile`
        font-size: 27px;
        font-weight: 500;
        line-height: 36px;
      `}
    }
`;
